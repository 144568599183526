import React from "react";
import './AboutPage.css';
import FirstElementAbout from "../../components/aboutcomponent/firstelementabout/FirstElementAbout";
import SecondElementAbout from "../../components/aboutcomponent/secondelementabout/SecondElementAbout";
import ThirdElementAbout from "../../components/aboutcomponent/thirdelementabout/ThirdElementAbout";
import FourthElementAbout from "../../components/aboutcomponent/fourthelementabout/FourthElementAbout";
import FourthSubElementAbout from "../../components/aboutcomponent/fourthsubelementabout/FourthSubElementAbout";
import FifthElementAbout from "../../components/aboutcomponent/fifthelementabout/FifthElementAbout";
import FifthSubElementAbout from "../../components/aboutcomponent/fifthsubelementabout/FifthSubElementAbout";
import Footer from '../../components/footer/Footer'
export default function AboutPage() {
    return (
        <div>
            <FirstElementAbout />
            <SecondElementAbout />
            <ThirdElementAbout />
            <FourthElementAbout />
            <FourthSubElementAbout />
            <FifthElementAbout />
            <FifthSubElementAbout/>
            <Footer />
        </div>
    )
}