import React from "react";
import './ThirdElementAbout.css';
import Goal1 from "../../../assets/goal1.svg";
import Goal2 from "../../../assets/goal2.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function ThirdElementAbout() {
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="about-main3">
                <img src={Goal1} className="finance" />
                <div className="about-main2-mid">
                    <div className="about-head-wrapper">
                        <div className="rectangle"></div>
                        <span className="about-head">Our Goals</span>
                    </div>
                    <span className="about-head-sub">Comprehensive Solutions, Pioneering Innovation & Scalability, Customer-Centric Approach</span>
                    <span className="about-head-detail">&#8226; We're a global network of over 40 experts specializing in various business domains. We understand the critical role that automation and systematic workflows play in the smooth operation of your daily activities. Our integrated approach ensures that you not only meet but exceed your clients' expectations.</span>
                    <span className="about-head-detail">&#8226; For us, customer satisfaction isn't just a metric; it's our highest priority. We are committed to understanding and meeting your unique needs, ensuring that you receive the best possible solutions.</span>
                    <span className="about-head-detail">&#8226; We don't just offer services; we offer solutions that are tailored to your specific needs while maintaining the highest standards of quality. From impeccable design and curated content to optimal speed and user-friendly tools, we strive for nothing less than your complete satisfaction. Moreover, our expertise extends to global market penetration, ensuring your products or services gain the visibility they deserve.</span>
                </div>
            </div>
        </AnimationOnScroll>
    )
}