import React from "react";
import './FirstElementAbout.css';
import NavBar from '../../navbar/NavBar';
import Business1 from '../../../assets/Business1.svg'
import Business2 from '../../../assets/Business2.svg'
import Business3 from '../../../assets/Business3.svg'
import { useNavigate } from "react-router-dom";
export default function FirstElementAbout() {
    const navigate = useNavigate()
    return (
        <div className="about-main">
            <NavBar />
            <div className="about-mid-container">
                <div className="about-content-container">
                    <span className="about-content-head">About Finbytz</span>
                    <span className="about-content-info">Making Business Effortless, One Solution at a Time</span>
                    <span className="about-content-detail">Welcome to Finbytz Solutions, where innovation meets integration, and together, we elevate your business to new heights.</span>
                    <div className="about-contact-button" onClick={() => navigate(`/contactus`)}>
                        <span>Contact Us</span>
                    </div>
                </div>
                <div className="about-images-container">
                    <div>
                        <img src={Business2} className="about-voice-svg" />
                        <img src={Business1} className="about-coworker-svg" />
                    </div>
                    <img src={Business3} className="about-group-svg" />
                </div>
            </div>
        </div>
    )
}