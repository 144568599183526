import React from "react";
import './LandingPage.css';
import FirstElementHome from "../../components/homecomponent/firstelementhome/FirstElementHome";
import SecondElementHome from "../../components/homecomponent/secondelementhome/SecondElementHome";
import ThirdElementHome from "../../components/homecomponent/thirdelementhome/ThirdElementHome";
import FourthElementHome from "../../components/homecomponent/fourthelementhome/FourthElementHome";
import FifthElementHome from "../../components/homecomponent/fifthelementhome/FifthElementHome";
import Footer from "../../components/footer/Footer";
import FourthSubElementHome from "../../components/homecomponent/fourthsubelementhome/FourthSubElementHome";

export default function LandingPage() {
    return (
        <div>
            <FirstElementHome />
            <SecondElementHome />
            <ThirdElementHome />
            <FourthElementHome />
            <FourthSubElementHome />
            <FifthElementHome />
            <Footer />
        </div>
    )
}