import React, { useState } from "react";
import "./FourthSubElementHome.css"
import ArrowRight from "../../../assets/arrow-right-icon.svg";
import ArrowLeft from "../../../assets/arrow-left-icon.svg";
import { AnimationOnScroll } from 'react-animation-on-scroll';
export default function FourthSubElementHome() {
    const [index, setIndex] = useState(0);
    const data = [
        {
            review:`"I've been a client of Finbytz for years, and they consistently deliver cutting-edge technology solutions. Their expertise in both IT and Fintech is unmatched. Great team to work with!"`,
            name:"Candace Turner",
            company:"Fashion Closet"
        },
        {
            review:`"We've been outsourcing various tasks to Fusiontec Solutions, and they have consistently delivered outstanding results. Their team is professional, efficient, and has become an extension of our own workforce. Impressed with their services!"`,
            name:"Ora McCaa",
            company:"Entrepreneur"
        },
        {
            review:`"Excellent customer service and visibility on a global scale, They must take everyone, and you will have everything under one roof."`,
            name:"Subhashree Panda",
            company:"Finance"
        },
        {
            review:`"Kudos to Finbytz for their outstanding outsourcing services. They have consistently delivered on their promises, providing us with the flexibility and scalability we need to thrive in a competitive market. A reliable partner indeed!"`,
            name:"Jamal Barger",
            company:"Entrepreneur"
        },
        {
            review:`"I can't say enough good things about Finbytz. Their team is knowledgeable, responsive, and dedicated to helping clients succeed in the ever-evolving world of IT and Fintech."`,
            name:"James Matson",
            company:"Portfolio"
        },
    ]

    return (
        <AnimationOnScroll animateIn="animate__fadeInLeft" duration={1.5}>
            <div className="main-sub-4">
                <div className="main-sub-4-mid">
                    <div className="head-wrapper5">
                        <div className="rectangle"></div>
                        <span className="head5">Testimonials</span>
                    </div>
                    <span className="head-sub5">What our clients says</span>
                    <span className="head-detail5">They have enthusiastically expressed their satisfaction with our services. They highlighted the exceptional level of professionalism and dedication demonstrated by our team. The client specifically praised our ability to understand their unique needs and deliver tailored solutions that exceeded their expectations. </span>
                </div>
                <div className="testimonial">
                    <span className="testimonial-msg">{data[index].review}</span>
                    <span className="testimonial-user">{data[index].name}</span>
                    <span className="user-class">{data[index].company}</span>
                    <div className="arrow-div">
                        <img src={ArrowLeft} alt="left" className="arrow-icon" onClick={()=>index > 0 &&  setIndex(index - 1)}/>
                        <img src={ArrowRight} alt="right" className="arrow-icon-right"  onClick={()=>index < data.length - 1 &&  setIndex(index+1)}/>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    )
}