import React from "react";
import './FirstElementHome.css'
import NavBar from "../../navbar/NavBar";
import GroupCall from "../../../assets/Grouphangout.svg";
import Coworkers from "../../../assets/Coworker.svg";
import Voicecontrol from "../../../assets/Voicecontrol.svg";
import { useNavigate } from "react-router-dom";
export default function FirstElementHome() {
    const navigate = useNavigate()
    return (
        <div className="main">
            <NavBar />
            <div className="mid-container">
                <div className="content-container">
                    <span className="content-head">Top digital agency</span>
                    <span className="content-info">We are best digital creative agency based in Etobicoke, Canada.</span>
                    <span className="content-detail">We enable organizations to fulfill their goals and tackle their most urgent issues. Boasting an expert team of consultants, and fintech professionals, along with a proven track record in delivering over 125+ projects. We provide astute strategic counsel, innovative solutions, and practical assistance to clients.</span>
                    <div className="contact-button" onClick={() => navigate(`/contactus`)}>
                        <span>Contact Us</span>
                    </div>
                </div>
                <div className="images-container">
                    <div>
                        <img src={Voicecontrol} className="voice-svg" />
                        <img src={Coworkers} className="coworker-svg" />
                    </div>
                    <img src={GroupCall} className="group-svg" />
                </div>
            </div>
        </div>

    )
}