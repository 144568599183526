import React, { useState } from "react";
import "./FifthElementAbout.css";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
} from "react-simple-maps"
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function FifthElementAbout() {
    const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";
    const markerdata = [
        {
            markerOffset: -30,
            name: "Buenos Aires",
            coordinates: [43.753860, -79.608688]
        }
    ];

    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="about-main5">
                <div className="about-main5-head">
                    <span className="about-main5-head">Our Locations</span>
                    <span className="about-main5-headsub">We Based Out In</span>
                </div>
                <div className="maps-wrapper">
                    {[1].map((d, i) => <div className="maps-cover" key={i}><ComposableMap projection="geoMercator" className="map">
                        <Geographies geography={geoUrl}
                            fill="skyblue">
                            {({ geographies }) =>
                                geographies.map((geo) => (
                                    <Geography key={geo.rsmKey} geography={geo} />
                                ))
                            }
                        </Geographies>
                        <Marker
                            style={{
                                default: {
                                    outline: "none",
                                },
                                hover: {
                                    outline: "none",
                                },
                                pressed: {
                                    outline: "none",
                                },
                            }}
                            key={1}
                            coordinates={[-79.608688, 43.753860]}
                        >
                            <circle r={3} fill="#F00" />
                        </Marker>
                    </ComposableMap></div>)}
                </div>
            </div>
        </AnimationOnScroll>
    )
}