import './App.css';
import { BrowserRouter } from "react-router-dom";
import Router from './routes/router';
import "animate.css/animate.min.css";
import ScrollToTop from "./utils/ScrollToTop";
function App() {
  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Router />
    </BrowserRouter>

  );
}

export default App;
