import React from "react";
import './FifthElementHome.css';
import DevelopmentTeam from "../../../assets/development-team.png";
import FinanceTeam from "../../../assets/finance-team.png";
import MarketingTeam from "../../../assets/digital-marketing.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';
export default function FifthElementHome() {
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="main5">
                <div className="main5-head-wrapper">
                    <span className="main5-head">Our Mains</span>
                    <span className="main5-headsub">What Major We Provide</span>
                </div>
                <div className="team-tag-wrapper">
                    <div className="team-tag">
                        <span className="tag-name">Accounting And<br/> Bookkeeping</span>
                        <div className="team-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="team-icon" />
                        </div>
                        <span className="team-size">5/5 <span className="team-member">star</span></span>
                        <div className="team-detail">
                            <span>Best Recommend Service</span>
                            <span>Efficient & Predictive Accounting Solutions</span>
                            <span>Phone & meeting support</span>
                        </div>
                    </div>
                    <div className="team-tag">
                        <span className="tag-name">FinTech Service</span>
                        <div className="team-icon-wrapper">
                            <img src={FinanceTeam} alt="icon" className="team-icon" />
                        </div>
                        <span className="team-size">5/5 <span className="team-member">star</span></span>
                        <div className="team-detail">
                        <span>Best Recommend Service</span>
                            <span>Efficient & Predictive FinTech Solutions</span>
                            <span>Phone & meeting support</span>
                        </div>
                    </div>
                    <div className="team-tag">
                        <span className="tag-name">Staffing Service</span>
                        <div className="team-icon-wrapper">
                            <img src={MarketingTeam} alt="icon" className="team-icon" />
                        </div>
                        <span className="team-size">5/5 <span className="team-member">star</span></span>
                        <div className="team-detail">
                        <span>Best Recommend Service</span>
                            <span>Efficient & Predictive Team Members</span>
                            <span>Phone & meeting support</span>
                        </div>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    )
}