import React from "react";
import "./SecondElementContact.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function SecondElementContact() {
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="contact-main2">
                <div className="contact-form">
                    <span className="form-head">Please use the form below for business inquiries</span>
                    <div className="name-email-input">
                        <input placeholder="Your Name" className="contact-name" />
                        <input placeholder="Your Email" className="contact-email" />
                    </div>
                    <input placeholder="Your Message" className="contact-message" />
                    <div className="contact-submit-button">
                        <span>Submit</span>
                    </div>
                </div>
                <div className="contact-main2-mid">
                    <div className="contact-head-wrapper">
                        <div className="rectangle"></div>
                        <span className="contact-head">Contact Form</span>
                    </div>
                    <span className="contact-head-sub"><span className="under-line">Discover</span> how tech is changing the nature of business</span>
                    <span className="contact-head-detail">While many of our clients face similar business and technology challenges, no two businesses are alike. A successful business cannot be built without a well-defined business strategy. A well-defined strategy is only as good as the plan put in place to operationalize it. We recognize that every business is unique and therefore requires a customized strategic roadmap.</span>
                </div>
            </div >
        </AnimationOnScroll >
    )
}