import React from "react";
import './FirstElementServices.css';
import NavBar from "../../navbar/NavBar";
import GroupCall from "../../../assets/services1.svg";
import Coworkers from "../../../assets/services2.svg";
import Voicecontrol from "../../../assets/services3.svg";
import { useNavigate } from "react-router-dom";
export default function FirstElementServices() {
    const navigate = useNavigate()
    return <div className="services-main">
        <NavBar />
        <div className="services-mid-container">
            <div className="services-content-container">
                <span className="services-content-head">Finbytz Services</span>
                <span className="services-content-info">Welcome to Finbytz Solutions</span>
                <span className="services-content-detail">We help you fuse Innovation with Technology for a Better Tomorrow. Finbytz Technologies has consistently aimed to simplify the complex world of business solutions.
                Whether It's Fintech, Accounting And Bookkeeping, Staffing, Or Company Growth Strategies.</span>
                <div className="about-contact-button" onClick={() => navigate(`/contactus`)}>
                    <span>Contact Us</span>
                </div>
            </div>
            <div className="services-images-container">
                <div>
                    <img src={Voicecontrol} className="services-voice-svg" />
                    <img src={GroupCall} className="services-coworker-svg" />
                </div>
                <img src={Coworkers} className="services-group-svg" />
            </div>
        </div>
    </div>
}