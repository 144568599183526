import React from "react";
import './SecondElementAbout.css';
import DevelopmentTeam from "../../../assets/development-team.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function SecondElementAbout() {
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="about-main2">
                <div className="about-main2-head-wrapper">
                    <span className="about-main2-head">Finbytz Fundamentals</span>
                    <span className="about-main2-headsub">We Have Roots As</span>
                </div>
                <div className="card-tag-wrapper">
                    <div className="card-tag">
                        <span className="card-name">Our Genesis</span>
                        <div className="card-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="card-icon" />
                        </div>
                        <div className="card-detail">
                            <span>Founded with the ambitious vision of becoming a one-stop platform for all your business needs, Finbytz has consistently aimed to simplify the complex world of business solutions.
                                Whether it's Fintech, Accounting and Bookkeeping, Staffing, or Company Growth Strategies.</span>
                        </div>
                    </div>
                    <div className="card-tag">
                        <span className="card-name">Our Mission</span>
                        <div className="card-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="card-icon" />
                        </div>
                        <div className="card-detail">
                            <span>Our mission is to make business effortless, one solution at a time. With a decade of industry experience and a portfolio spanning 200+ international projects, we are committed to delivering unparalleled value to your business at competitive prices.</span>
                        </div>
                    </div>
                    <div className="card-tag">
                        <span className="card-name">Our Intention</span>
                        <div className="card-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="card-icon" />
                        </div>
                        <div className="card-detail">
                            <span>Our intention is to be the leading integrated solutions provider, committed to empowering businesses with innovative and efficient services that drive sustainable growth also To offer services from Accounting to financial management, that simplifies business processes.</span>
                        </div>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>)
}