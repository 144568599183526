import React from "react";
import "./ThirdElementHome.css";
import Development from "../../../assets/Development.svg";
import Consultancy from "../../../assets/Consultancy.svg";
import Fintech from "../../../assets/Fintech.svg";
import Booking from "../../../assets/Booking.svg";
import Account from "../../../assets/Account.svg";
import Outsourcing from "../../../assets/Outsourcing.svg";
import Marketing from "../../../assets/Marketing.svg"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom";
export default function ThirdElementHome() {
    const navigate = useNavigate()
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5} >
            <div className="main3">
                <div className="main3-mid">
                    <div className="head-wrapper">
                        <div className="rectangle"></div>
                        <span className="head">Services</span>
                    </div>
                    <span className="head-sub">Our abilities for solutions</span>
                    <span className="head-detail">Finbytz aims to provide a comprehensive range of services such as Accounting and Bookkeeping, Staffing, Digital Marketing, Financial Management, and Company Growth Strategies. Our core mission is to simplify and streamline operations for businesses of all sizes, allowing them to initiate and expand without the hassle of seeking services from multiple providers.</span>
                    <div className="read-button" onClick={() => navigate(`/services`)}>
                        <span>View All</span>
                    </div>
                </div>
                <div className="services-grid">
                    <div className="column-one">
                        <div className="grid-element">
                            <img src={Development} className="grid-icon" />
                            <span>Lending <br/>solutions</span>
                        </div>
                        <div className="grid-element">
                            <img src={Account} className="grid-icon" />
                            <span>Accounting</span>
                        </div>
                    </div>
                    <div className="column-two">
                        <div className="grid-element">
                            <img src={Booking} className="grid-icon" />
                            <span>Booking</span>
                        </div>
                        <div className="grid-element">
                            <img src={Outsourcing} className="grid-icon" />
                            <span>Outsourcing</span>
                        </div>
                        <div className="grid-element">
                            <img src={Marketing} className="grid-icon" />
                            <span>Marketing</span>
                        </div>
                    </div>
                    <div className="column-three">
                        <div className="grid-element">
                            <img src={Fintech} className="grid-icon" />
                            <span>Fintech</span>
                        </div>
                      
                        <div className="grid-element">
                            <img src={Consultancy} className="grid-icon" />
                            <span>Staffing</span>
                        </div>
                    </div>
                </div>
            </div>
        </ AnimationOnScroll>
    )
}