import React from "react";
import './NavBar.css';
import Arrow from '../../assets/Arrow.png';
import Ellipse from '../../assets/Ellipse.png';
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo.png"
export default function NavBar() {
    const navigate = useNavigate();
    return (
        <div className="navbar">
            {/* <h1 className="navbar-head">Fusiontecz</h1> */}
            <img src={Logo} className="navbar-head-img" onClick={()=>navigate('/')}/>
            <div className="navbar-content-wrapper">
                <div className="navbar-content">
                    <h3> <Link to="/" className="no-effect">Home</Link></h3>
                    <h3> <Link to="/about" className="no-effect">About Us</Link></h3>
                    <h3><Link to="/services" className="no-effect">Services</Link></h3>
                    <h3><Link to="/contactus" className="no-effect">Contact Us</Link></h3>
                    {/* <h3><Link to="/howitworks" className="no-effect">How It Works</Link></h3> */}
                </div>
                <div className="talk-wrapper">
                    <Link to="/letstalk" className="no-effect">
                        <h3>Let's Talk</h3>
                    </Link>
                    <div className="vector-wrapper">
                        <img src={Arrow} className="arrow-icon" />
                        <img src={Ellipse} className="ellipse-icon" />
                    </div>

                </div>
            </div>

        </div>
    )
}