import React from "react";
import "./SecondElementServices.css";
import DevelopmentTeam from "../../../assets/development-team.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function SecondElementServices() {
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="services-main2">
                <div className="services-main2-head-wrapper">
                    <span className="services-main2-head">Finbytz Services</span>
                    <span className="services-main2-headsub">We Provide You</span>
                </div>
                <div className="template-tag-wrapper">
                    <div className="template-tag">
                        <span className="template-name">IT Consulting</span>
                        <div className="template-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="template-icon" />
                        </div>
                        <div className="template-detail">
                            <span>We understand that in today's fast-paced business landscape, staying ahead requires more than just technology – it demands strategic IT solutions tailored to your unique needs. With a deep-rooted commitment to innovation, excellence, and client satisfaction, we have emerged as a beacon of expertise in the IT consulting realm. </span>
                        </div>
                    </div>
                    <div className="template-tag">
                        <span className="template-name">Fintech Consulting</span>
                        <div className="template-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="template-icon" />
                        </div>
                        <div className="template-detail">
                            <span>We provide Fintech consulting solutions that redefine the way businesses navigate the financial technology landscape. In today's rapidly evolving financial world, success hinges on the ability to harness the full potential of innovative digital solutions. We specialize in helping you seize these opportunities.</span>
                        </div>
                    </div>
                    <div className="template-tag">
                        <span className="template-name">Accounting and<br/> Bookkeeping</span>
                        <div className="template-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="template-icon" />
                        </div>
                        <div className="template-detail">
                            <span>Our account and bookkeeping service is designed to streamline financial processes and provide businesses with accurate, organized, and up-to-date financial records. We understand the critical importance of maintaining clear and transparent financial documentation, and our team of skilled professionals is dedicated to ensuring that our clients' financial records are both accurate and compliant with relevant regulations.</span>
                        </div>
                    </div>
                    <div className="template-tag">
                        <span className="template-name">Staffing Solutions</span>
                        <div className="template-icon-wrapper">
                            <img src={DevelopmentTeam} alt="icon" className="template-icon" />
                        </div>
                        <div className="template-detail">
                            <span>Finbytz where talent meets opportunity and businesses find their perfect fit. In today's competitive and dynamic job market, finding the right talent for your organization is paramount. We are your dedicated partner in sourcing, screening, and placing top-notch professionals who align seamlessly with your company's goals and culture. </span>
                        </div>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    )
}