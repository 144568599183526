import React from "react";
import './FirstElementContact.css';
import NavBar from "../../navbar/NavBar";
import GroupCall from "../../../assets/contact1.svg";
import Coworkers from "../../../assets/contact2.svg";
import Voicecontrol from "../../../assets/contact3.svg";
export default function FirstElementContact() {
    return <div className="contact-main">
        <NavBar />
        <div className="contact-mid-container">
            <div className="contact-content-container">
                <span className="contact-content-head">Contact Our Agency</span>
                <span className="contact-content-info">We are best digital creative agency based in Etobicoke, Canada.</span>
                <span className="contact-content-detail">We enable organizations to fulfill their goals and tackle their most urgent issues. Boasting an expert team of consultants, and fintech professionals, along with a proven track record in delivering over 125+ projects. We provide astute strategic counsel, innovative solutions, and practical assistance to clients.</span>
            </div>
            <div className="contact-images-container">
                <div>
                    <img src={Voicecontrol} className="contact-voice-svg" />
                    <img src={Coworkers} className="contact-coworker-svg" />
                </div>
                <img src={GroupCall} className="contact-group-svg" />
            </div>
        </div>
    </div>
}