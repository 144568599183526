import React from "react";
import './Services.css';
import Footer from "../../components/footer/Footer";
import FirstElementServices from "../../components/servicescomponent/firstelementservices/FirstElementServices";
import SecondElementServices from "../../components/servicescomponent/secondelementservices/SecondElementServices";
import FourthElemnetServices from "../../components/servicescomponent/fourthelementservices/FourthElementServices"
import FourthSubElementAbout from "../../components/aboutcomponent/fourthsubelementabout/FourthSubElementAbout";
import FifthElementAbout from "../../components/aboutcomponent/fifthelementabout/FifthElementAbout";
export default function Services() {
    return <div>
        <FirstElementServices />
        <SecondElementServices />
        <FourthElemnetServices />
        <FourthSubElementAbout />
        <FifthElementAbout />
        <Footer />
    </div>
}