import React, { useState } from "react";
import "./FifthSubElementAbout.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Avatar1 from "../../../assets/avatar1.png";
import Avatar2 from "../../../assets/avatar2.jpg";
export default function FifthSubElementAbout() {
   return <AnimationOnScroll animateIn="animate__fadeInLeft" duration={1.5}>
          <div className="about-main-sub5">
                <div className="about-main-sub5-mid">
                    <div className="about-main-sub5-head">
                        <div className="rectangle"></div>
                        <span className="main4-head">Our Founders</span>
                    </div>
                    <div className="founder-wrapper">
                    <div className="founder-container">
<img className="founder-image" src={Avatar1}/>
<div className="founder-details">
    <span className="founder-name">Jaz Singh</span>
    <span className="founder-bio">Boasting over a decade of international experience, Jaz is multi-skilled with certifications in areas such as Fintech Lending, Financial Valuation and Modelling, Project Management, Data Science, and Machine Learning. Having successfully completed more than 100 projects in over 20 countries across diverse sectors, he also holds marketing credentials from an educational standpoint and has extensive experience in various international markets.</span>
</div>
</div>
<div className="founder-container">
<img className="founder-image" src={Avatar2}/>
<div className="founder-details">
    <span className="founder-name">Kamal Kaur</span>
    <span className="founder-bio">She is an IT professional by profession with 14+ years in international market for development, testing and project management. She is also lending and real estate broker for Canadian Market. She has done 200+ projects in various countries of different domains. Also, she has worked with well reputed International brands in her professional career.</span>
</div>
</div>
                    </div>
                </div>
                    
          </div>
    </AnimationOnScroll>
}