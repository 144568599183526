import React from "react";
import './Footer.css';
import Google from '../../assets/google.svg'
import Facebook from '../../assets/facebook.svg'
import Youtube from '../../assets/youtube.svg'
import Dribble from '../../assets/dribbble.svg'
import Twitter from '../../assets/twitter.svg'
import { useNavigate } from "react-router-dom";
export default function Footer() {

    const navigate = useNavigate();

    return (
        <div className="footer">
            <div className="footer-top">
                <div className="explore-div">
                    <span className="explore-head">Explore</span>
                    <div onClick={()=>navigate('/')}>
                    <span className="explore-detail">Home</span>
                    </div> 
                    <div onClick={()=>navigate('/about')}>
                    <span className="explore-detail">About Us</span>
                    </div>    
                    <div onClick={()=>navigate('/services')}>
                    <span className="explore-detail">Services</span> 
                    </div>
                    <div onClick={()=>navigate('/contactus')}>
                    <span className="explore-detail">Contact Us</span> 
                    </div>           
                   
                </div>
                <div className="queries-div">
                    <span className="queries-head">Any Queries ?</span>
                    <div className="queries-child">
                        <span className="child-head">Our Location</span>
                        <span className="child-detail">204-66 Guided Crt,<br/> Etobicoke, ON, M9V4K6, Canada</span>
                    </div>
                    <div className="queries-child">
                        <span className="child-head">Call Us</span>
                        <span className="child-detail">+1-519-760-3940</span>
                    </div>
                    <div className="queries-child">
                        <span className="child-head">Send Us Email</span>
                        <span className="child-detail">info@finbytz.com</span>
                    </div>
                </div>
                <div className="question-div">
                    <span className="head-sub2">Got a project ? <br /> Let's talks.</span>
                    <span className="head-detail2">Just feel free to contact if you wanna collaborate with us, or simply have a conversation.</span>
                    <div className="read-button2" onClick={()=>navigate('/contactus')}>
                        <span>Contact Us</span>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <span>© 2023 Creatific. All rights reserved. Design by <b>Finbytz</b></span>
                <div className="footer-social-icons">
                    <img src={Facebook} className="footer-icon" />
                    <img src={Google} className="footer-icon" />
                    <img src={Dribble} className="footer-icon" />
                    <img src={Youtube} className="footer-icon" />
                    <img src={Twitter} className="footer-icon" />
                </div>
            </div>
        </div>
    )
}