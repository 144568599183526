import React from "react";
import "./FourthElementAbout.css";
import CountUp from 'react-countup';
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function FourthElementAbout() {
    return (
        <AnimationOnScroll animateIn="animate__fadeInRight" duration={1.5}>
            <div className="about-main4">
                <div className="about-main4-mid">
                    <div className="about-main4-head">
                        <div className="rectangle"></div>
                        <span className="main4-head">Ratings</span>
                    </div>
                    <span className="main4-head-sub5">What our rating says</span>
                    <span className="main4-head-detail5">Our client's ratings speak volumes about the quality of our offerings. With an impressive average rating of 4.8 out of 5, our clients consistently express high satisfaction. The feedback highlights the seamless user experience, exceptional customer support, and the reliability of our products/services. </span>
                </div>
                <div className="rating-wrapper">
                    <div className="client-rating-wrapper">
                        <CountUp className="rating-number" start={0} end={200} delay={1} enableScrollSpy={true} suffix="+" />
                        <span className="rating-tag">Happy Clients</span>
                        <span className="ratings">200 Ratings</span>
                    </div>
                    <div className="project-rating-wrapper">
                        <CountUp className="rating-number" start={0} end={300} delay={1} enableScrollSpy={true} suffix="+" />
                        <span className="rating-tag">Projects Delivered</span>
                        <span className="ratings">5 Ratings</span>
                    </div>
                    <div className="team-rating-wrapper">
                        <CountUp className="rating-number" start={0} end={10} delay={1} enableScrollSpy={true} suffix="+" />
                        <span className="rating-tag">Team Members</span>
                        <span className="ratings">3500 Ratings</span>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    )
}