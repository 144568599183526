import React from "react";
import './FourthSubElementAbout.css';
import LocationIcon from '../../../assets/box.svg'
import MessageIcon from '../../../assets/message.svg';
import PaperPlane from '../../../assets/paper-plane.svg'
import { AnimationOnScroll } from "react-animation-on-scroll";
export default function FourthSubElementAbout() {

    return (
        <AnimationOnScroll animateIn="animate__fadeInLeft" duration={1.5}>
            <div className="about-fourthsub-main">
                <span className="fourthsub-head">Want to get in touch with us? Let’s talk</span>
                <div className="about-main5-bottom">
                    <div className="bottom-element">
                        <div className="element-icon-wrapper">
                            <img src={LocationIcon} alt="location" className="element-icon" />
                        </div>
                        <span className="element-head">Our Location in Canada</span>
                        <span className="element-text">204-66 Guided Crt,<br/> Etobicoke, ON, M9V4K6, Canada</span>
                    </div>
                    <div className="bottom-element">
                        <div className="element-icon-wrapper">
                            <img src={MessageIcon} alt="message" className="element-icon" />
                        </div>
                        <span className="element-head">+1-519-760-3940</span>
                        <span className="element-text">Let us know how we can help.</span>
                    </div>
                    <div className="bottom-element">
                        <div className="element-icon-wrapper">
                            <img src={PaperPlane} alt="plane" className="element-icon" />
                        </div>
                        <span className="element-head">info@finbytz.com</span>
                        <span className="element-text">Learn more about our products.</span>
                    </div>
                </div>

            </div>
        </AnimationOnScroll>)
}