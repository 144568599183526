import React from "react";
import './ContactUs.css';
import FourthSubElementAbout from "../../components/aboutcomponent/fourthsubelementabout/FourthSubElementAbout";
import FifthElementAbout from "../../components/aboutcomponent/fifthelementabout/FifthElementAbout";
import Footer from "../../components/footer/Footer";
import FirstElementContact from "../../components/contactcomponent/firstelementcontact.jsx/FirstElementContact";
import SecondElementContact from "../../components/contactcomponent/secondelementcontact/SecondElementContact";
export default function ContactUs() {
    return <div>
        <FirstElementContact />
        <SecondElementContact />
        <FourthSubElementAbout />
        <FifthElementAbout />
        <Footer />
    </div>
}