import React from "react";
import "./FourthElementHome.css"
import Arrow from "../../../assets/Arrow.png";
import Ellipse from "../../../assets/Ellipse.png"
import DevelopmentProject from "../../../assets/Development.jpg.webp";
import FinanceProject from "../../../assets/FinanceProject.png";
import Accounting from "../../../assets/accounting.webp"
import Accounting2 from "../../../assets/accounting2.jpg"
import Accounting3 from "../../../assets/accounting3.jpeg"
import Staffing from "../../../assets/staffing.jpg";
import Staffing2 from "../../../assets/staffing2.jpg";
import Fintech2 from "../../../assets/fintech-banking.jpg"
import { AnimationOnScroll } from 'react-animation-on-scroll';
export default function FourthElementHome() {
    const pictures = [
    { image: Staffing, name: "BuyerFolio Staff", tag: "Staffing Project" },
    { image: Accounting, name: "Belayempire", tag: "Accounting Project" },
    { image: DevelopmentProject, name: "Lyvelending.com", tag: "FinTech Project" },
    { image: Fintech2, name: "BuyerFolio.ai", tag: "FinTech Project" },
    { image: Staffing2, name: "FusionTecz Staff", tag: "Staffing Project" },
    { image: Accounting3, name: "Ibridgecapital.ca", tag: "Accounting Project" },
    { image: FinanceProject, name: "Adhire Industry", tag: "Finance Project" },
    { image: Accounting2, name: "Infixkyc.com", tag: "Accounting Project" }];

    function Card({ data }) {
        return (
            <div className="scroll-card">
                <img src={data.image} alt="picture" className="card-cover" />
                <div className="card-bottom" >
                    <span className="project-name">{data.name}</span>
                    <span className="project-tag">{data.tag}</span>
                </div>
            </div>
        )
    }

    return (
        <AnimationOnScroll animateIn="animate__fadeInRight" duration={1.5}>
            <div className="main4">
                <div className="main4-head-container">
                    <div className="main4-head-wrapper">
                        <div className="rectangle"></div>
                        <span className="head4">Projects</span>
                    </div>
                    <div className="main4-head-content">
                        <h1 className="main4-head">Latest Work</h1>
                        <div className="main4-content-wrapper">
                            <div className="main4-content">
                                <h3>All</h3>
                                <h3>Finance</h3>
                                <h3>Fintech</h3>
                                <h3>Digital Marketing</h3>
                                <h3>Accounting</h3>
                                <h3>Booking</h3>
                                <h3>Development</h3>
                            </div>
                            <div className="main4-talk-wrapper">
                                <h3>View All</h3>
                                <div className="main4-vector-wrapper">
                                    <img src={Arrow} className="main4-arrow-icon" />
                                    <img src={Ellipse} className="main4-ellipse-icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="scroller">
                    {
                        pictures.map((data, i) => (
                            <Card key={i} data={data} />
                        ))
                    }
                </div>
            </div>
        </AnimationOnScroll>
    )
}