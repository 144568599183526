import React from "react";
import "./SecondElementHome.css";
import Finance from "../../../assets/Finance.svg";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useNavigate } from "react-router-dom"
export default function SecondElementHome() {
    const navigate = useNavigate()
    return (
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5}>
            <div className="main2">
                <img src={Finance} className="finance" />
                <div className="main2-mid">
                    <div className="head-wrapper">
                        <div className="rectangle"></div>
                        <span className="head">About Us</span>
                    </div>
                    <span className="head-sub">We are making Business Effortless</span>
                    <span className="head-detail">Welcome to Finbytz, where innovation meets integration, and together, we elevate your business to new heights. Finbytz Technologies aims to provide a comprehensive range of services such as Accounting and Bookkeeping, Staffing, Digital Marketing, Financial Management, and Company Growth Strategies.</span>
                    <div className="read-button" onClick={() => navigate(`/about`)}>
                        <span>Read more</span>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    )
}