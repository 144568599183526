import React from "react";
import "./FirstElementChat.css";
import NavBar from "../../navbar/NavBar";
import Business1 from '../../../assets/chat1.svg'
import Business2 from '../../../assets/chat2.svg'
import Business3 from '../../../assets/chat3.svg'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from "../../../assets/brandmark.png"
export default function FirstElementChat() {
    return (
        <div className="chat-main">
            <NavBar />
            <div className="about-mid-container">
                <div className="about-content-container">
                    <span className="about-content-head">Chat With Finbytz</span>
                    <span className="about-content-info">We're Just A Message Away From You</span>
                    <span className="about-content-detail">Welcome to Finbytz Solutions, Is there something specific you'd like to talk about or any questions you have in mind? Feel free to start the conversation, and We'll do our best to assist you.</span>
                </div>
                <div className="about-images-container">
                    <div>
                        <img src={Business3} className="about-voice-svg" />
                        <img src={Business1} className="about-coworker-svg" />
                    </div>
                    <img src={Business2} className="about-group-svg" />
                </div>
            </div>
            <FloatingWhatsApp style={{
                height: "10%"
            }} 
            avatar={Logo}
            phoneNumber={'+15197603940'} 
            accountName="Finbytz"
            />
        </div>

    )
}