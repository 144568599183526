import { Routes, Route } from "react-router-dom";
import LandingPage from '../pages/landingpage/LandingPage';
import AboutPage from "../pages/aboutpage/AboutPage";
import HowItWorks from "../pages/howitworks/HowItWorks";
import ContactUs from "../pages/contactus/ContactUs";
import Services from "../pages/services/Services";
import ChatPage from "../pages/chatpage/ChatPage";

export default function Router() {
    return (
        <Routes>
            <Route path="/" index element={<LandingPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/letstalk" element={<ChatPage />} />
        </Routes>
    );
}