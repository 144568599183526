import React from "react";
import './HowItWorks.css';
import FirstElementHiw from "../../components/hiwcomponent/firstelementhiw/FirstElementHiw";
import Footer from "../../components/footer/Footer";
export default function HowItWorks() {
    return (
        <div>
            <FirstElementHiw />
            <Footer />
        </div>
    )
}